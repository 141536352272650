.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Map */
.leaflet-container {
    height: 55vh;
    background: white;
}

.leaflet-control-container {
    bottom: 50%;
    right: 4rem;
    width: 2rem;
    height: 72px;
    position: absolute;
}

.leaflet-control-attribution {
    display: none;
}

div.leaflet-touch .leaflet-bar {
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

div.leaflet-touch .leaflet-bar a:first-child,
div.leaflet-touch .leaflet-bar a:last-child {
    border: 1px solid black;
    border-radius: 1rem;
    background-color: black;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

div.leaflet-bar a {
    border-bottom: none;
}

div.leaflet-bar a.leaflet-disabled {
    background-color: transparent;
    color: #c9c9c9;
}

div.leaflet-touch .leaflet-bar a:first-child {
    padding-bottom: 2rem;
}

fieldset {
    border: 0 !important;
}

.leaflet-container.leaflet-touch-drag {
    height: 65vh;
    background: white;
}
