@font-face {
    font-family: "Berlingske";
    src: url("assets/fonts/Berlingske_ExtraBold.woff");
}

@font-face {
    font-family: "Work Sans";
    src: url("assets/fonts/WorkSans-Regular.ttf");
}

@font-face {
    font-family: "Work Sans Bold";
    src: url("assets/fonts/WorkSans-Bold.ttf");
}

html {
    font-family: "Work Sans", sans-serif !important;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
